import _ from 'lodash';
import { FRONTEND_DATA } from "../../../globals";
import { helpers } from "@cargo/common";


const marqueeSetPlugin = function(_core){

	'use strict'

	var _this = this;
	var selectedMarqueeSet = null;

	var onCursorActivity = function(editor){

		var editorEl = editor?.getElement?.();
		if( !editorEl){
			return;
		}

		var range = _core.getActiveRange();

		let marqueeSet = null;

		if( range ){

			let parentCheck = range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE ? range.commonAncestorContainer : range.commonAncestorContainer.parentElement
			if( parentCheck ){
				marqueeSet = parentCheck.closest('marquee-set');				
			}

		}

		if( marqueeSet !== selectedMarqueeSet){
			if( marqueeSet && marqueeSet.built && editorEl.contains(marqueeSet)  ){
				marqueeSet.built = false;
			}

			if( selectedMarqueeSet && !selectedMarqueeSet.built){
				selectedMarqueeSet.built =true;
			}

			selectedMarqueeSet = marqueeSet

		}

	}


	var onKeyDown = function(editor, event){

		if( !selectedMarqueeSet ){
			return;
		}

		var range = _core.getActiveRange();
		let marqueeInner = selectedMarqueeSet.querySelector('marquee-inner[slot="contents"]');
		let placeCursorAfter = false;
		let placeCursorBefore = false;
		let checkNode;

		if( !marqueeInner || !range || !range.collapsed){
			return;
		}

		switch(event.key){
			case "ArrowDown":
			case "ArrowRight":
			case "Enter":

				checkNode = range.endContainer;
				placeCursorAfter = range.endContainer.nodeType == Node.TEXT_NODE ? range.endOffset == range.endContainer.length : range.endOffset == range.endContainer.childNodes.length;
				while(checkNode && checkNode.parentNode && checkNode !== marqueeInner && placeCursorAfter){
					if( checkNode.parentNode.childNodes[checkNode.parentNode.childNodes.length-1] != checkNode ){
						placeCursorAfter = false
					}
					checkNode = checkNode.parentNode
				}
				break;

			case "ArrowUp":
			case "ArrowLeft":
				checkNode = range.startContainer;
				placeCursorBefore = range.startOffset == 0;
				while(checkNode && checkNode.parentNode && checkNode !== marqueeInner && placeCursorBefore){
					if( checkNode.parentNode.childNodes[0] != checkNode ){
						placeCursorBefore = false
					}
					checkNode = checkNode.parentNode
				}
				break
		}

		if( placeCursorAfter || placeCursorBefore){

			var newRange = _core.rangy.createRange();

			var spacerNode = FRONTEND_DATA.contentWindow.document.createElement('div')
			spacerNode.setSaveable(false);
			spacerNode.style.display = 'inline'
			spacerNode.innerHTML='&nbsp;'				

			if( placeCursorBefore){
				selectedMarqueeSet.parentNode.insertBefore(spacerNode, selectedMarqueeSet);
				newRange.setStartBefore(spacerNode);
				newRange.setEndBefore(spacerNode);
			
			} else {
				selectedMarqueeSet.parentNode.insertBefore(spacerNode, selectedMarqueeSet.nextSibling);
				newRange.setStartAfter(spacerNode);
				newRange.setEndAfter(spacerNode);
			}

			_core.helpers.setActiveRange(newRange);
			spacerNode.remove();
		}
	}
	

	this.events.on('editor-keydown', onKeyDown);
	this.events.on('cursor-activity', onCursorActivity);
	
	this.destroy = function(){
		this.events.off('editor-keydown', onKeyDown);		
		this.events.off('cursor-activity', onCursorActivity);
	}

}

export default marqueeSetPlugin

