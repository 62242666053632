import _ from 'lodash';
import { FRONTEND_DATA } from "../../../globals";
import {uploadURL} from '../../media';
import { getCRDTItem } from "../../multi-user/redux";
import selectors from "../../../selectors";
import { helpers } from "@cargo/common";

/**
 *	Copypaste is a self contained plugin that automatically adds itself to every editor that gets created. It listens
 *	to the <code>editor-copy</code>, <code>editor-cut</code> and <code>editor-paste</code> events, and <code>preventDefaults</code>
 *	them to allow us to standardize behavior across browsers.
 * 
 * @module copypaste
 */

export default function(CargoEditor){

	/**
	 * Prevents the default copy-paste action from happening.
	 */

	'use strict'

	var _this = this,
		shownProjectImageAlert = false;

	var sanitizeDoc = document.implementation.createHTMLDocument('sanitizeDoc'),
		allowedTags = [ 'p', 'iframe', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'figcaption', 'blockquote', 'small', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'u', 'strike', 'img', 'code', 'hr', 'br', 'table', 'thead', 'tfoot', 'tbody', 'caption', 'tr', 'td', 'th', 'pre', 'div', 'meter', 'progress', 'input', 'column-set', 'column-unit', 'media-item', 'audio-player', 'shop-product', 'text-icon', 'digital-clock'],
		voidTags = ['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'param', 'source', 'track', 'wbr', 'meter', 'progress', 'input', 'iframe', 'text-icon', 'digital-clock'],
		allowedAttributes = {
			a: [ 'href', 'name', 'target', 'rel' ],
			img: [ 'src', 'width', 'height' ],
			div: [ 'style' ],
			figcaption: ['slot', 'class'],
			'column-unit': ['slot', 'span', 'class'],
			meter: ['value', 'min', 'max', 'low', 'high', 'optimum'],
			progress: ['value', 'max'],
			input: ['type', 'name', 'value'],
			iframe: ['src', 'width', 'height', 'allowfullscreen', 'webkitallowfullscreen', 'mozallowfullscreen', 'frameborder', 'allow'],
		},
		allowedStyles = ['display', 'border-width', 'border-color', 'border-style', 'filter', 'will-change'];

	function recursivelyRemoveEmptyNode(node) {
		
		if(node.parentNode !== null && node.nodeType === Node.ELEMENT_NODE && voidTags.indexOf(node.nodeName.toLowerCase()) === -1 && node.childNodes.length === 0) {

			var parent = node.parentNode;
			parent.removeChild(node);

			// Check the parent, removing this node might have emptied it as well.
			recursivelyRemoveEmptyNode(parent);

		}

	}

	function sanitizeNode(node) {

		if(node.nodeType === Node.COMMENT_NODE) {

			if(node.parentNode !== null) {
				node.parentNode.removeChild(node);
			}

		} else if(node.nodeType === Node.TEXT_NODE) {

			if(node.data.match(/\S/) !== null) {
				// trim node and remove multiple spaces
				node.data = node.data.replace(/^[\t\r\n\f]+|[\t\r\n\f]+$/gm, '').replace(/\ {2,}/g, ' ');
			}

			// replace &nbsp;'s that are not preceded by a space with a simple plain space
			// replace single &nbsp;'s in between two words (one&nbsp;two > one two)
			node.data = node.data.replace(/([^ $])\u00A0$/gm, "$1 ")
								 .replace(/([^ ])(?:\u00A0)([^ ])/, '$1 $2')
								 .replace(/^\u00A0+([^ ])/g, ' $1')
								 .replace(/^\u00A0$/g, ' ');

		} else if(node.nodeType === Node.ELEMENT_NODE) {

			if(node.childNodes.length === 0 && ! node.tagName === 'COLUMN-UNIT') {
			
				recursivelyRemoveEmptyNode(node);
			
			}

		}

	}

	function sanitizeHTML(HTML) {

		var range = CargoEditor.getActiveRange(),
			hasTextAlignedParent = false;

		if(range !== undefined) {

			var parent = range.commonAncestorContainer;

			if(parent.nodeType !== Node.ELEMENT_NODE) {
				parent = parent.parentNode;
			}

			while(parent !== null && ! CargoEditor.helpers.isEditingHost(parent)) {

				if(parent.nodeType == Node.ELEMENT_NODE && CargoEditor.helpers.isEditable(parent) && parent.style.textAlign != ""){ 
					hasTextAlignedParent = true;
				}

				parent = parent.parentNode;

			}

		}

		var allElements,
			copiedFromEditor = false;

		if(sanitizeDoc.body === null) {
			sanitizeDoc.documentElement.appendChild(sanitizeDoc.createElement('body'));
		}

		if(typeof HTML === "string") {

			sanitizeDoc.body.innerHTML = HTML;
			allElements = sanitizeDoc.body.querySelectorAll('*');

			if(
				sanitizeDoc.getElementsByTagName('copied-from-editor').length !== 0
				// in certain cases copying text from the editor into another window browsers still
				// add inline styles to our shit:
				//
				// Chrome -> Chrome is totally fine even across origins
				// Safari <-> Chrome is not ok
				// Safari -> Safari only ok on same origin
				//
				// For now we just detect this by checking for an obscure inline style (widows: auto)
				// the browser would inject. If found, we sanitize.
				&& !HTML.includes('widows: auto')
			) {
				copiedFromEditor = true;
			}

		} else if( typeof HTML === "object") {

			allElements = HTML;

		} else {

			return;

		}

		if(hasTextAlignedParent) {
			CargoEditor.helpers.removeAlignmentFromElements([].slice.call(allElements));
		}

		var	tagName,
			node,
			i;

		for(i = allElements.length - 1; i >= 0; i--) {

			node = allElements[i];

			if(node.nodeType !== Node.ELEMENT_NODE) {
				continue;
			}

			if(node.hasAttribute('data-prevent-copy')) {
				if(node.parentNode !== null) {
					node.parentNode.removeChild(node);
					continue;
				}
			}

			tagName = node.nodeName.toLowerCase();

			// ditch blob images
			if((node.nodeName === "IMG" || node.nodeName === "MEDIA-ITEM") && node.hasAttribute('src') && !node.hasAttribute('data-mid') && /blob:http/.test(node.getAttribute('src'))) {
				
				if(node.parentNode !== null) {
					node.parentNode.removeChild(node);
					continue;
				}

			}

			// Google docs wraps the whole content in a b tag with font-weight: normal which is
			// undesirable
			if(tagName === "b" && node.style.fontWeight == 'normal') {
				CargoEditor.helpers.unwrap(node, true);
				continue;
			}

			// remove uneditable nodes
			if(node.attributes.getNamedItem('contenteditable') !== null && tagName !== "audio-player") {

				if(node.attributes.getNamedItem('contenteditable').value == "false") {
					
					if(node.parentNode !== null) {
						node.parentNode.removeChild(node);

						// node is removed, done here.
						continue;
					}

				}

			}

			// replace em with i
			if(!copiedFromEditor && tagName === "em" && allowedTags.indexOf('i') !== -1) {

				var li = node.ownerDocument.createElement('i');

				node.parentNode.insertBefore(li, node);

				while(node.childNodes.length > 0) {
					li.appendChild(node.childNodes[0]);
				}

				node.parentNode.removeChild(node);

				continue;

			}

			// check if tag is allowed, only
			if(allowedTags.indexOf(tagName) === -1) {

				// the node is not allowed, and empty, delete it.
				// Also forcing the deletion of style tags because the css inside it 
				// counts as text node and will show up as text in the final result.
				if(node.childNodes.length === 0 || tagName == "style") {

					if(node.parentNode !== null) {
						node.parentNode.removeChild(node);

						// node is removed, the next steps aren't applicable anymore.
						continue;
					}

				} else {

					if(!copiedFromEditor) {

						if(tagName === 'title' && node.parentNode !== null) {

							node.parentNode.removeChild(node);

						} else {

							// the node is not empty, unwrap it so the children will live on.
							CargoEditor.helpers.unwrap(node, true);

						}

						// node is removed, the next steps aren't applicable anymore.
						continue;

					}

				}

			}

			// check for allowed attributes (does not include data attributes!)
			var allowed = allowedAttributes[tagName],
				attrIndex, attr;

			// we use div's with a text-align to align text, don't remove these.
			if(!copiedFromEditor && node.nodeName === "DIV") {

				if(['left', 'center', 'right'].indexOf(node.style.textAlign) !== -1) {

					var textAlignment = node.style.textAlign;

					// make sure text-align is the only property active
					node.setAttribute('style', 'text-align: ' + textAlignment + ';');

				} else {

					// div without text align will be an attribute-less div. Remove.
					CargoEditor.helpers.unwrap(node, true);

					continue;

				}

			}

			if(!copiedFromEditor) {

				const isCustomElement = FRONTEND_DATA.contentWindow.customElements.get(node.nodeName.toLowerCase());
				// treat custom elements and 'uses' differently
				// allow all attributes, focus on removing hashes
				if( isCustomElement || node.hasAttribute('uses') ){

					if( node.nodeName==='MEDIA-ITEM'){
						node.removeAttribute('hash');
						node.removeAttribute('poster');
						node.removeAttribute('dynamic-src');
					}

					for (let i = node.style.length; i--; ) {

					  const nameString = node.style[i];

					  if( allowedStyles.indexOf(nameString) ==-1){
					  	node.style.removeProperty(nameString);
					  }

					  if( Object.values(node.style).length == 0){
					  	node.removeAttribute('style');
					  }
					  
					}


				} else {

					for(attrIndex = node.attributes.length - 1; attrIndex >= 0; attrIndex--) {
						
						attr = node.attributes[attrIndex];

						// Check if attr is allowed for this tag
						if(allowed !== undefined && allowed.indexOf(attr.name) !== -1) {
							continue;
						}

						node.attributes.removeNamedItem(attr.name);

					}
				}

				


			}

		}

		sanitizeDoc.body.normalize();

		// We don't really want <p> tags in Persona, but removing them will mess with 
		// the markup of the copied content. Remove the <p> but add breaks around it.
		for(i = 0; i < allElements.length; i++) {

			if(allElements[i].tagName === "P") {

				if(allElements[i].parentNode !== null) {

					if(allElements[i].previousSibling !== null) {
						allElements[i].parentNode.insertBefore(allElements[i].ownerDocument.createElement('br'), allElements[i]);
						allElements[i].parentNode.insertBefore(allElements[i].ownerDocument.createTextNode('\n'), allElements[i]);
					}

					if(allElements[i].nextSibling !== null) {
						allElements[i].parentNode.insertBefore(allElements[i].ownerDocument.createTextNode('\n'), allElements[i].nextSibling);
						allElements[i].parentNode.insertBefore(allElements[i].ownerDocument.createElement('br'), allElements[i].nextSibling);
					}


				}

				CargoEditor.helpers.unwrap(allElements[i], true);

			}

		}

		if( typeof HTML === "object") {

			_.each(allElements, sanitizeNode);

		} else {

			CargoEditor.helpers.getAllDescendants(sanitizeDoc.body, sanitizeNode, true);

		}

		return sanitizeDoc.body.innerHTML;
	}

	function pasteHTMLString(pastedHTML, editor, range) {
		sanitizeHTML(pastedHTML);

		CargoEditor.mutationManager.execute(function(){

			if(range.collapsed === false) {
				CargoEditor.helpers.splitRange(range);
				range.deleteContents();

				var summaries = editor.generateUnreportedMutationSummaries();

				_.each(summaries, function(summary){
					CargoEditor.plugins.columns.correctColumns(summary, editor);
				});

				range = CargoEditor.getActiveRange();
			}

			var parent = range.commonAncestorContainer,
				parentArray = [],
				nodesToUnwrap = [],
				hasParentColumn = false;

			if(parent.nodeType === Node.TEXT_NODE) {
				parent = parent.parentNode;
			}

			// find all parent tagnames
			while(parent !== null && parent !== editor.getElement()){

				if(parent.tagName === 'COLUMN-SET' || parent.tagName === 'COLUMN-UNIT') {
					hasParentColumn = true;
				}

				if(parent.nodeName !== undefined) {
					parentArray.push(parent.nodeName);
				}

				parent = parent.parentNode;

			}

			// retrieve all pasted nodes that match one of the parents's nodeNames
			CargoEditor.helpers.getAllDescendants(sanitizeDoc.body, function(node){

				node.setSaveable(true);

				if(hasParentColumn) {

					if(node.nodeType === Node.ELEMENT_NODE && (node.tagName === 'COLUMN-SET' || node.tagName === 'COLUMN-UNIT') ) {
						nodesToUnwrap.push(node);
					}

				}
				
				if(
					node.nodeName !== "DIV" 
					// retain nested style only spans for stuff like font color or size
					&& !(node.nodeName === "SPAN" && node.attributes.length === 1 && node.attributes[0].nodeName === 'style')
					&& parentArray.indexOf(node.nodeName) !== -1
				){
					nodesToUnwrap.push(node);
				}

			});

			// make sure we unwrap bottom-up instead of down the tree
			nodesToUnwrap.reverse();

			// unwrap all nodes that are duplicates of one of the parents
			_.each(nodesToUnwrap, function(node){
				CargoEditor.helpers.unwrap(node, true);
			});

			var lastChild = sanitizeDoc.body.lastChild;

			while(sanitizeDoc.body.childNodes.length > 0) {
				range.insertNode(sanitizeDoc.body.lastChild);
			}

			if( lastChild !== null ){
				// set cursor after the last child
				var newRange = CargoEditor.rangy.createRange();

				newRange.setStartAfter(lastChild);
				newRange.setEndAfter(lastChild);

				newRange.collapse();
				CargoEditor.helpers.setActiveRange(newRange);

				setTimeout(function(){
					newRange.collapse();
					CargoEditor.helpers.setActiveRange(newRange);
				});

			}

			_this.events.trigger('editor-pasted-html', editor);

		});

	}

	_this.events.on('editor-copy', function(editor, event) {

		handleCopyOrCutEvent(editor, event);

	});

	_this.events.on('editor-cut', function(editor, event) {

		if(event.defaultPrevented) {
			return;
		}

		handleCopyOrCutEvent(editor, event);

		var range = CargoEditor.getActiveRange();

		if(range !== undefined && range.collapsed === false) {
			CargoEditor.mutationManager.execute(function(){

				CargoEditor.helpers.splitRange(range);
				range.deleteContents();

				range = CargoEditor.getActiveRange();
				CargoEditor.forcedRange = null;

				if(range !== undefined) {
					var parent = range.commonAncestorContainer;

					if(parent.nodeType !== Node.ELEMENT_NODE) {
						parent = parent.parentNode;
					}

					while(
						parent
						&& parent.nodeName !== "COLUMN-UNIT"
						&& parent.nodeName !== "COLUMN-SET"
						&& CargoEditor.helpers.removeNodeIfEmptyAndCorrectRange(parent, range)
					) {
						parent = parent.parentNode;
					}

				}

			});
		}

	});

	function handleCopyOrCutEvent(editor, event) {

		[].forEach.call(editor.getElement().querySelectorAll('column-set'), function(row){

			row.setAttribute('data-column-count', row.querySelectorAll('column-unit').length);

		});

		var	range = CargoEditor.getActiveRange(),
			plainText = range.toString(),
			contents = range.cloneContents(),
			parent = range.commonAncestorContainer,
			allParents = [];

		if(parent.nodeType !== Node.ELEMENT_NODE) {
			parent = parent.parentNode;
		}

		while(
			parent !== null 
			&& parent !== parent.ownerDocument.body 
			&& parent !== editor.getElement()
			&& parent.nodeName !== 'FIGCAPTION'
		) {

			allParents.push(parent.cloneNode());

			parent = parent.parentNode;

		}

		allParents.reverse();

		_.each(allParents, function(node, index){

			if(index === 0) {
				contents.appendChild(node);
			} else {
				allParents[index - 1].appendChild(node);
			}

		});

		if(allParents.length > 0) {
			
			while(contents.childNodes.length > 1) {

				allParents[allParents.length - 1].appendChild(contents.childNodes[0]);

			}

		}

		if(event.clipboardData) {
			event.preventDefault();

			contents.appendChild(contents.ownerDocument.createElement('copied-from-editor'));
			
			[].forEach.call(contents.querySelectorAll('[data-prevent-copy]'), function(node){

				if(node.parentNode !== null) {
					node.parentNode.removeChild(node);
				}

			});

			[].forEach.call(contents.querySelectorAll('column-set'), function(row){

				var colCount = parseInt(row.getAttribute('data-column-count'));
				var cols = row.querySelectorAll('column-unit');

				if(!(! isNaN(colCount) && colCount == cols.length)) {

					[].forEach.call(cols, function(col) {
						CargoEditor.helpers.unwrap(col, true);
					});

					CargoEditor.helpers.unwrap(row, true);

				}

				row.removeAttribute('column-count');

			});

			var serializer = new XMLSerializer(),
				copiedContentAsHTMLString = serializer.serializeToString(contents);

			event.clipboardData.setData('text/html', copiedContentAsHTMLString.replace(/\s?xmlns=\".*?\"/g, ''));
			event.clipboardData.setData('text/plain', plainText);
			//event.clipboardData.setData('text/source', 'inline-editor');

		}

		[].forEach.call(editor.getElement().querySelectorAll('[data-column-count]'), function(row){

			row.removeAttribute('data-column-count');

		});
	}

	_this.events.on('editor-paste', function(editor, event) {

		// after paste, check mutations for newly-created media-items with srcs, then add urls into the library
		CargoEditor.events.once('mutation-before-success', (editor)=>{

			const state = store.getState();
			const existingURLs = selectors.getMediaByParent(state)[state.frontendState.PIDBeingEdited]?.filter((item)=>item.is_url);

			var toUpload = new Map();	
			var summaries = editor.generateUnreportedMutationSummaries();

			summaries.forEach(summary=>{
				summary.added.forEach(node=>{
					if( node.nodeName== 'MEDIA-ITEM' && node.hasAttribute('src') ){

						let url = node.getAttribute('src');
				    	const existingModel = existingURLs.find(item=>item.url === url);

						if( existingModel ){

                        	if( existingModel.url_type === 'video' || existingModel.url_type === 'vimeo' || existingModel.url_type === 'youtube' ){
                        		node.setAttribute('browser-default', 'true');
                        		node.setAttribute('disable-zoom', 'true');
							}
							node.setAttribute('hash', existingModel.hash);
							node.removeAttribute('src');

						} else {

							const src =node.getAttribute('src');
							const urlInfo = helpers.getFiletypeAndOembedFromURL(src);

							if( urlInfo.fileType == 'vimeo' || urlInfo.fileType == 'youtube'){
								toUpload.set(node.getAttribute('src'), node);
							}

						}							

					}
				});
			})

			toUpload.forEach((mediaItem, url, map)=>{
				uploadURL({
					target: getCRDTItem({ reducer: 'pages.byId', item: state.frontendState.PIDBeingEdited }),
					field: 'media'
				}, url).then(result => {
					if ( mediaItem){
					    CargoEditor.mutationManager.execute(()=>{

	                        mediaItem.setAttribute('hash', result.model.hash);
	                        mediaItem.removeAttribute('src');

	                        if( result.model.is_url &&
	                        	(result.model.url_type === 'video' || result.model.url_type === 'vimeo' || result.model.url_type === 'youtube')
                        	){
                        		mediaItem.setAttribute('browser-default', 'true');
                        		mediaItem.setAttribute('disable-zoom', 'true');
	                        }
	                    }, {
	                        // this change should not be able to cause a page to enter draft mode
	                        preventDraft: true				
	                    });					
					}
				});							
			});			
		});		
		
		/*console.log(event.clipboardData.files.length);
		console.log(event.clipboardData.items);
		console.log(event.clipboardData.types);

		if(event.clipboardData.items !== undefined){
			[].forEach.call(event.clipboardData.items, function(item){
				console.log('\n-----\n');
				console.log(item.kind, item.type);
			});
		}

		if(event.clipboardData.types !== undefined){
			[].forEach.call(event.clipboardData.types, function(type){
				console.log('\n-----\n');
				console.log(type, ':', event.clipboardData.getData(type));
			});
		}*/

		if(event.clipboardData.files.length > 0) {
			// files were pasted. Do not copy them into the editor
			console.log('ignoring file paste')
			event.preventDefault();
			return;
		}

		var dataFormatHTML = 'text/html',
			dataFormatText = 'text/plain',
			pastedHTML,
			pastedText;

		if (FRONTEND_DATA.contentWindow.clipboardData && event.clipboardData === undefined) {

			event.clipboardData = FRONTEND_DATA.contentWindow.clipboardData;

			dataFormatHTML = 'Text';
			dataFormatText = 'Text';
		}

		if (event.clipboardData && event.clipboardData.getData && !event.defaultPrevented) {

			pastedHTML = event.clipboardData.getData(dataFormatHTML);
			pastedText = event.clipboardData.getData(dataFormatText);

			var range = CargoEditor.getActiveRange(),
				isHTMLEmbed = false;
			if(range !== undefined) {

				// check for youtube, google maps, or vimeo embeds inside of pasted text, and convert them to HTML.
				if((typeof pastedHTML !== "string" || pastedHTML.length === 0) && typeof pastedText === "string") {

					// <iframe width="560" height="315" src="https://www.youtube.com/embed/0ZrXwq5MHB0" frameborder="0" allowfullscreen></iframe>
					if(pastedText.match(/\s*<iframe[\s\S]*(src=".*youtube.+\/embed\/.+")[\s\S]*><\/iframe>\s*$/gi) !== null) {
						isHTMLEmbed = true;
					} 
					// <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13214.769875790453!2d-118.2468147!3d34.103017900000005!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1449101814265" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
					else if(pastedText.match(/\s*<iframe[\s\S]*(src=".*google.+\/maps\/.+")[\s\S]*>[\s\S]*<\/iframe>\s*$/gi) !== null) {
						isHTMLEmbed = true;
					}
					// <iframe src="https://player.vimeo.com/video/94957294" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> <p><a href="https://vimeo.com/94957294">Domestic Appliances</a> from <a href="https://vimeo.com/lewisfirthbolton">Lewis Firth Bolton</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
					else if(pastedText.match(/\s*<iframe[\s\S]*(src=".*vimeo.+\/video\/[\s\S]+")[\s\S]*>(<\/iframe>|[\s\S]*[<\/p>|| <\/script>])\s*$/) !== null) {
						isHTMLEmbed = true;
					} 

					// just capture all iframes - the mutation listener will filter out ones that need to get hashes
					else if(pastedText.match(/\s*<iframe/) ){
						isHTMLEmbed = true;
					}

					if(isHTMLEmbed) {

						pastedText = pastedText.replace('iframe', 'media-item');
						event.preventDefault();

						CargoEditor.events.once('mutation-before-success', (editor)=>{
							var summaries = editor.generateUnreportedMutationSummaries();
							summaries.forEach(summary=>{
								summary.added.forEach(node=>{

									if( node.nodeName === 'P' || node.nodeName ==='SCRIPT'){
										node.remove();
									}

									// remove the padding-hack divs from vimeo embeds
									if(
										node.nodeType === Node.ELEMENT_NODE
										&& node.style.padding
										&& node.style.position === 'relative'
										&& node.firstElementChild
										&& node.firstElementChild.tagName === 'MEDIA-ITEM'
										&& node.parentNode
									){
										node.firstElementChild.removeAttribute('style');
										node.parentNode.insertBefore(node.firstElementChild, node);
										node.parentNode.removeChild(node);
									}

									if( node.nodeName== 'MEDIA-ITEM'){

										// bandcamp embeds use inline styles... transfer those styles over to attributes
										if(
											(!node.hasAttribute('width') || !node.hasAttribute('height'))
											&& (node.style.width && node.style.height)
										){

											if( node.style.width.indexOf('px') > -1 ){
												node.setAttribute('width', parseFloat(node.style.width));
												node.setAttribute('height', parseFloat(node.style.height));

												node.setAttribute('limit-by', 'width');
												node.setAttribute('scale', node.style.width);
											}
										}

										// remove percentages from soundcloud embeds
										// replace with a suitable 'wide' aspect ratio
										if( node.getAttribute('width')?.trim() === '100%') {
											node.setAttribute('width', 1200 );
										}


										node.setAttribute('browser-default', true);
										node.setAttribute('disable-zoom', true);

										node.removeAttribute('scrolling');

										if(node.style){
											node.style.width = null;
											node.style.height = null;
										}
										
										node.removeAttribute('allow');
										node.removeAttribute('title');
										node.removeAttribute('frameborder');
										node.removeAttribute('allowfullscreen');
										node.childNodes.forEach(childNode=>{
											childNode.remove();
										});
									}
								});
							})

						});

						CargoEditor.execCommand('inserthtml', false, pastedText);

						return;

					}

				}

				if(typeof pastedHTML === "string" && pastedHTML.length > 0){

					event.preventDefault();

					pasteHTMLString(pastedHTML, editor, range);

				} else if(typeof pastedText === "string" && pastedText.length > 0) {

					event.preventDefault();

					var containsHTML = /<[a-z][\s\S]*>/i.test(pastedText);

					// if the pasted text contains html, we don't autoconvert URLs,
					// as this will break attributes containing URLs.
					if( ! containsHTML ) {
						// there's text to be pasted and no to time to be wasted.
						pastedText = parseAndConvertUrls(pastedText);
					}

					// breaks
					pastedText = pastedText.replace(/\n/g, '<br />\n');

					// no breaks in svg
					pastedText = pastedText.replace(/<svg[\s\S]*?>([\s\S]*?)<\/svg>/gi, function(match){
						return match.replace(/<br \/>/g, '')
					});

					CargoEditor.mutationManager.execute(function(){

						var frag = range.createContextualFragment(pastedText);

						// simple link insert. Just link selected text
						if(
							!range.collapsed
							// if the pasted text is a single link.
							&& (frag.childNodes.length === 1 && frag.childNodes[0].nodeName === "A")
							// and the selection only contains one node
							&& range.getNodes().length === 1
						) {

							var nodesInRange = range.getNodes();

							if(nodesInRange[0].nodeType === Node.TEXT_NODE || nodesInRange[0].nodeName === 'TEXT-ICON' || nodesInRange[0].nodeName === 'DIGITAL-CLOCK') {

								// link text
								var selectedText = range.extractContents();
								var link = frag.childNodes[0];

								// move selected text inside link
								link.innerHTML = '';
								link.appendChild(selectedText);

								// insert the link and select it
								range.insertNode(link);
								range.selectNode(link)

								CargoEditor.helpers.setActiveRange(range);
								
								_this.events.trigger('editor-pasted-link', editor, link);

								return;

							} else if(nodesInRange[0].nodeName === "MEDIA-ITEM") {
								
								// link image
								var link = frag.childNodes[0];
								var mediaItem = nodesInRange[0];

								Array.from(link.attributes).forEach(function(linkAttribute) {
									mediaItem.setAttribute(linkAttribute.name, linkAttribute.value);
								})
								
								_this.events.trigger('editor-pasted-link', editor, mediaItem);

								return;

							}

						}

						if(range.collapsed === false) {
							CargoEditor.helpers.splitRange(range);
							range.deleteContents();
						}

						CargoEditor.helpers.getAllDescendants(frag, function(node){

							if(node.nodeName === "SCRIPT" || node.nodeName === "STYLE") {

								// remove br's from script and style tags
								CargoEditor.helpers.getAllDescendants(node, function(innerNode){
									if(innerNode.nodeType === Node.TEXT_NODE) {
										innerNode.data = innerNode.data.replace(/<br \/>/g, '');
									}
								});
								
								return "continue";

							} else if(node.nodeType === Node.TEXT_NODE) {

								// tabs
								node.data = node.data.replace(/\t/g, '\xA0 \xA0 ');

								// whitespace
								//node.data = node.data.trim().replace(/\ {2,}/g, ' ');

							}

						});

						var scripts = [];

						_.each(frag.querySelectorAll('script'), function(node){

							scripts.push({
								node: node,
								parentNode: (node.parentNode === frag ? null : node.parentNode),
								nextSibling: node.nextSibling 
							});

							node.parentNode.removeChild(node);

						});

						var lastNode = frag.lastChild;

						if(frag.childNodes.length > 0) {
							range.insertNode(frag);
						}

						// add scripts
						var errorOccured = false,
							onError = function(e){
								errorOccured = true;
							};

						FRONTEND_DATA.contentWindow.addEventListener('error', onError, false);

						_.each(scripts, function(script){

							// clear any unrelated errors
							errorOccured = false;

							if(script.parentNode === null) {
								script.parentNode = range.commonAncestorContainer;

								if(script.parentNode.nodeType === Node.TEXT_NODE) {
									script.parentNode = script.parentNode.parentNode;
								}
							}

							if(script.nextSibling && script.nextSibling.parentNode === script.parentNode) {
								script.parentNode.insertBefore(script.node, script.nextSibling);
							} else {
								script.parentNode.appendChild(script.node);
							}

							if(errorOccured) {
								
								// reset
								errorOccured = false;

								script.node.parentNode.insertBefore(document.createComment(script.node.outerHTML), script.node);
								script.node.parentNode.removeChild(script.node);

								if(typeof Cargo !== "undefined") {
									Cargo.Modal.Alert({
										message: '<em>One or more scripts on this page are broken and were disabled.</em>'
									});
								}

							}

						});

						FRONTEND_DATA.contentWindow.removeEventListener('error', onError);

						if(lastNode !== null) {
							range.setStartAfter(lastNode);
							range.setEndAfter(lastNode);
						}

						CargoEditor.helpers.setActiveRange(range);
						
					});
					

				}

			}

			// Get the text, but parse it for URLs first
			/*var textData = parseAndConvertUrls( event.clipboardData.getData('text/plain') );

			// If no data exists for the specified format, the getData method 
			// returns null in Internet Explorer, an empty string in Firefox 
			// and undefined in Google Chrome and Safari
			
			if(textData !== null && textData !== undefined && textData !== ''){
				// there's text to be pasted and no to time to be wasted.
				
				var selection = window.getSelection();

				if(selection.rangeCount > 0){
					var range = selection.getRangeAt(0);

					// breaks
					textData = textData.replace(/\n/g, '<br />');

					// tabs
					textData = textData.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

					var nodes = _this.helpers.stringToHTMLNodes(textData);

					CargoEditor.mutationManager.execute(function(){

						// silently delete selection as it's not supposed to have it's own
						// summary callback
						if( ! range.collapsed ) {
							CargoEditor.execCommandSilent('delete');
							range.collapse();
						}

						[].forEach.call(nodes, function(node){
							range.insertNode(node);
						});

					});

					_this.events.trigger('cursor-activity', editor);
					
				}
				
			}*/

		}

	});
	
	/**
	 * Parse and convert URLs to wrap them in a link tag
	 * This will wrap both URLs and Email addresses in an appropriate link
	 * and return back the result 
	 * @param  {String} input The paste content
	 * @return {String} 
	 */
	var parseAndConvertUrls = function(plain) {
        var vimeoRegExp = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
		var youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var reg = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
		var rendered = plain;



		if(reg.test(plain)) {

			plain.replace(reg, function(match, text, urlId){

				var link_string = match;

				if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(match)) {

					link_string = '<a href=mailto:'+match+'>'+match+'</a>';

				} else {
					var link_url = match;

					if( link_url.match(vimeoRegExp) || link_url.match(youtubeRegExp) || link_url.endsWith('.mp4') || link_url.endsWith('.mov') || link_url.endsWith('.ogv') ){

						link_string = '<media-item src="'+link_url+'"></media-item>'

					} else {

						if(!/(http(s)?:\/\/)/.test(match)) {
							link_url = 'https://'+link_url;
						}
						link_string = '<a href='+link_url+' target="_blank">'+match+'</a>';						
					}


				}

				rendered = rendered.replace(match, link_string);
			});
		}

		return rendered;
	}

}
