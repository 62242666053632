import _ from 'lodash';

export default function(CargoEditor) {

	'use strict';

	var embed_scripts_urls = [
		/platform.twitter.com\/widgets.js/gi,
		/widget.bandsintown.com\/main.min.js/gi,
		/widgetv3.bandsintown.com\/main.min.js/gi,
		/widget.songkick.com\/*.*\/widget.js/gi,
		/widget-app.songkick.com\/injector\/\d*/gi,
		/embed.typeform.com\/next\/embed.js/gi,
		/cdn.shoprocket.io\/loader.js/gi
	];

	function parseTweetURL(url) {

		if(typeof url !== "string") {
			return null;
		}

		if (url.indexOf('status') === -1) {
			return null;
		}

		var match = /\/status(es)?\/(\d+)/.exec(url);
		
		if (match === null) {
			return null;
		}

		return {
			id: match[2]
		}

	}

	function datasetToObject(elem){
		var data = {};
		[].forEach.call(elem.attributes, function(attr) {
			if (/^data-/.test(attr.name)) {
				var camelCaseName = attr.name.substr(5).replace(/-(.)/g, function ($0, $1) {
					return $1.toUpperCase();
				});
				data[camelCaseName] = attr.value;
			}
		});
		return data;
	}


	var checkForNewEmbeds = function(editor, summary){

		if(summary) {

			// nodes that have class changes and are not already in summary.added
			const nodesWithClassChanges = _.difference(summary.attributeChanged.class || [], summary.added);

			_.each([...summary.added, ...nodesWithClassChanges], function(node){

				if(node && node.nodeType === Node.ELEMENT_NODE && !node.closest('cargo-embed')) {

					// embedded tweets
					if(node.nodeName === "BLOCKQUOTE" && node.className === "twitter-tweet") {
						
						/*
							<blockquote class="twitter-tweet"><p lang="en" dir="ltr">Sunsets don&#39;t get much better than this one over <a href="https://twitter.com/GrandTetonNPS">@GrandTetonNPS</a>. <a href="https://twitter.com/hashtag/nature?src=hash">#nature</a> <a href="https://twitter.com/hashtag/sunset?src=hash">#sunset</a> <a href="http://t.co/YuKy2rcjyU">pic.twitter.com/YuKy2rcjyU</a></p>&mdash; US Dept of Interior (@Interior) <a href="https://twitter.com/Interior/status/463440424141459456">May 5, 2014</a></blockquote>
							<script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>
						 */
						
						var links = node.querySelectorAll('a'),
							statusURL = links[links.length - 1];

						if(statusURL) {
							
							var tweetInfo = parseTweetURL(statusURL.href);

							if(tweetInfo) {

								var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
									'data-type': 'tweet',
									'data-id': tweetInfo.id
								});

								_.each(node.attributes, function(attr) {
									if (/^data-/.test(attr.name)) {
										wrap.setAttribute(attr.name, attr.value);
									}
								});

								wrap.setSaveable(true);

							}
						}

						// embedded twitter timeline
					} else if(node.nodeName === "A" && node.className === "twitter-timeline") {

						/*
							// profiles, likes, lists, and collections
							<a class="twitter-timeline" href="https://twitter.com/TwitterDev?ref_src=twsrc%5Etfw">Tweets by TwitterDev</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

							twttr.widgets.createTimeline(
							  {
								sourceType: 'url',
								url: 'https://twitter.com/twitterdev'
							  },
							  document.getElementById('container')
							);

							// search timeline
							<a class="twitter-timeline"  href="https://twitter.com/search?q=https%3A%2F%2Fwww.periscope.tv%2F" data-widget-id="946459581948899328">Tweets about https://www.periscope.tv/</a>
							<script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+"://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>

							twttr.widgets.createTimeline(
							  "600756918018179072",
							  document.getElementById("container"),
							  {
								height: 400
							  }
							);

							<a class="twitter-timeline" data-width="400" data-height="600"
								data-link-color="#0000ee" href="
								https://twitter.com/bot_content?ref_src=twsrc%5Etfw">Tweets by
								bot_content</a> <script async src="https://platform.twitter.com/widgets.js"
								charset="utf-8"></script>

						 */
					
						var dataSource,
							options = datasetToObject(node);

						if(node.hasAttribute('data-widget-id')) {
							dataSource = node.getAttribute('data-widget-id');
						} else if(node.hasAttribute('href')) {
							dataSource = JSON.stringify({
								url: node.getAttribute('href').trim(),
								sourceType: 'url'
							});
						} 

						if(dataSource) {
							
							var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
								'data-type': 'twitter-timeline',
								'data-source': dataSource
							});

							if(_.keys(options).length > 0) {
								try {
									wrap.setAttribute('data-options', JSON.stringify(options));
								} catch(e) {

								}
							}

							wrap.setSaveable(true);
						}

					  // embedded bandsintown
					} else if(node.nodeName === "A" && node.classList.contains('bit-widget-initializer')) {

						var widgetVersion;

						_.each(summary.added, function(node){
							
							if(
								node 
								&& node.nodeName === "SCRIPT"
								&& typeof node.src === "string"
								&& node.src.includes('widgetv3')
							) {
								widgetVersion = 'v3'
							}

						})

						/*

						v3 widget:
							<script charset="utf-8" src="https://widgetv3.stg.bandsintown.com/main.min.js"></script>
							<a class="bit-widget-initializer"
								data-font="syne"
								data-language="en"
								data-display-details="false"
								data-artist-name="Your Band"
							></a>

						*/


						/*

						v2 widget: 
							<script charset="utf-8"
								src="https://widget.bandsintown.com/main.min.js"></script><a
								class="bit-widget-initializer" data-artist-name="Traverse Town"
								data-display-local-dates="false" data-display-past-dates="true"
								data-auto-style="false" data-text-color="#000000" data-link-color="#2F95DE"
								data-popup-background-color="#FFFFFF" data-background-color="#FFFFFF"
								data-display-limit="15" data-link-text-color="#FFFFFF"></a>

						 */

						var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
							'data-type': 'bandsintown'
						});

						_.each(node.attributes, function(attr) {
							if (/^data-/.test(attr.name)) {
								wrap.setAttribute(attr.name, attr.value);
							}
						});

						if(widgetVersion !== undefined) {
							wrap.setAttribute('data-bit-widget-version', widgetVersion)
						}

						wrap.setSaveable(true);

						// songkick
					} else if(node.nodeName === "A" && node.classList.contains('songkick-widget')) {

						/*
						<a href="https://www.songkick.com/artists/9044964" class="songkick-widget" data-theme="light" data-track-button="on" data-font-color="#fff" data-background-color="transparent">Cyberattack tour dates</a>
						<script src="//widget.songkick.com\/9044964\/widget.js"></script>
						*/

						if(node.hasAttribute('href')) {

							var idmatch = /\/artists\/(\d*)/gi.exec(node.getAttribute('href'));

							if(idmatch && idmatch[1]) {

								var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
									'data-type': 'songkick',
									'data-id': idmatch[1]
								});

								_.each(node.attributes, function(attr) {
									if (/^data-/.test(attr.name)) {
										wrap.setAttribute(attr.name, attr.value);
									}
								});

								wrap.setSaveable(true);

							}

						}

						// typeform
					} else if(node.nodeName === "DIV" && (node.hasAttribute('data-tf-widget') || node.hasAttribute('data-tf-live'))) {

						// embed.typeform.com/next/embed.js
						// <div data-tf-widget="bM64QCzz" data-tf-opacity="100" data-tf-hide-headers data-tf-inline-on-mobile data-tf-iframe-props="title=My typeform" data-tf-transitive-search-params data-tf-medium="snippet" style="width:100%;height:700px;"></div>
						// <script src="//embed.typeform.com/next/embed.js"></script>


						// new version. Note `data-tf-live` instead of `data-tf-widget`
						// <div data-tf-live="01JAAS8FJ9FY0B44QJPFFRXKTG"></div><script src="https://embed.typeform.com/next/embed.js"></script>

						var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
							'data-type': 'typeform',
							'data-embed': _.escape(node.outerHTML)
						});

						wrap.setSaveable(true);

					} else if(
						node.parentNode 
						&& node.nodeName === "SCRIPT" 
						&& node.src.match(/\.(opentable)\..*\/widget\/reservation\/loader/)
					) {

						
						// <script 
						// 	type='text/javascript' 
						// 	src='//www.opentable.com.au/widget/reservation/loader?rid=169841&type=standard&theme=wide&color=6&dark=false&iframe=true&domain=comau&lang=en-AU&newtab=false&ot_source=Restaurant%20website'
						// ></script>

						var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
							'data-type': 'opentable',
							'data-src': node.src
						});

						wrap.setSaveable(true);

						// remove default script 
						node.remove();

					} else if(node.nodeName === "DIV" && node.classList.contains('sr-element')) {
						// shoprocket

						// <div class="sr-element sr-products" data-embed="multiple_products">
						// <script type="application/json" data-config="embed">{"publishable_key":"sr_live_pk_0ac696797e14d49ca01f523ffb8d962e670f","options":{"products_to_display":"all","categories":[]},"includes":{"show_search_box":"1","show_sort_by":"1","show_per_page":"1","show_category_dropdown":"1","show_currency_dropdown":"1","show_language_dropdown":"1","show_product_filters":"1","show_product_name":"1","show_product_price":"1","show_product_image":"1","show_product_summary":"1","open_modal_on_image_click":"1","show_view_product_button":"1","show_add_to_cart_button":"1","show_min_max_order_quantity":"1","show_sale":"1","show_free_shipping":"1","show_new_product":"1","show_digital_download":"1","image_swap":"1","show_button_icons":"1"}}
						// </script>
						// </div>
						// <script async src="https://cdn.shoprocket.io/loader.js"></script>

						var wrap = CargoEditor.helpers.wrap(node, 'cargo-embed', {
							'data-type': 'shoprocket',
							'data-embed': _.escape(node.outerHTML)
						});

						wrap.setSaveable(true);

						// remove default embed 
						node.remove();

					} else if(node.parentNode && node.nodeName === "SCRIPT") {

						// remove the embed script tags, we'll include them.
						_.each(embed_scripts_urls, function(regex){

							if(node.src.match(regex) !== null || node.innerText.match(regex)) {
								node.parentNode.removeChild(node);
							}

						});

					}

				}

			});
		}

	};

	// attach the plugin to editors that instanciate at a later time
	this.events.on('editor-instanciated', _.bind(checkForNewEmbeds, this));

	this.events.on('mutation-before-success', function(editor){

		if(editor === undefined) {
			return;
		}

		var summaries = editor.generateUnreportedMutationSummaries();

		_.each(summaries, function(summary){
			checkForNewEmbeds(editor, summary);
		}, this);

	}, this);

};