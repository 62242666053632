import _ from 'lodash';
import { FRONTEND_DATA } from "../../../globals";

const columnsPlugin = function(_core){

	var currentCommand,
		rangeOnMutationStart;

	var correctColumns = function(summary, editor) {

		if(currentCommand === "insertorderedlist" || currentCommand === "insertunorderedlist") {
			return;
		}

		var range = _core.getActiveRange(),
			node, i, oldParent, referenceIndex, oldPreviousSibling, deletedColumnContents = [];

		var textNodesRemovedByDeletion = [],
			elementsToRemove = [],
			elementsToCheck = [];

		elementsToCheck = elementsToCheck.concat(summary.removed);
		elementsToCheck = elementsToCheck.concat(summary.reparented);

		if(currentCommand === "delete" || currentCommand === 'forwarddelete') {
			_.each(summary.added, function(node){
				if(node.parentNode !== null && node.nodeName === "BR") {
					node.parentNode.removeChild(node);
				}
			});

			textNodesRemovedByDeletion = summary.removed.filter(node=>node.nodeType === Node.TEXT_NODE);
		}


		for(i = 0; i < elementsToCheck.length; i++) {

			oldParent = summary.getOldParentNode(elementsToCheck[i]);

			if(
				(oldParent.nodeType === Node.ELEMENT_NODE && oldParent.nodeName === 'COLUMN-SET')
			) {
				// only remove the entire column set if 
				if(_core.helpers.isEmptyNode(oldParent) && oldParent.children.length < 1 && textNodesRemovedByDeletion.length == 0) {
					// the grid is either completely empty, or one empty col remains. Delete it.
					if(elementsToRemove.indexOf(oldParent) === -1  ){
						elementsToRemove.push(oldParent);
					}
				}
				deletedColumnContents.push(elementsToCheck[i]);
			} 

		}


		deletedColumnContents.forEach(function(node, i, context) {

			oldPreviousSibling = summary.getOldPreviousSibling(node),
			referenceIndex = context.indexOf(oldPreviousSibling);

			// The array is not in the right order. Switch the reference and current node 
			// to ensure nodes are inserted after their reference nodes have been inserted
			// in the right place
			while (referenceIndex > i) {

				node = context[referenceIndex];
				oldPreviousSibling = summary.getOldPreviousSibling(node);

				// swap the nodes around
				context[referenceIndex] = context[i];
				context[i] = node;

				// check the index of the new reference node
				referenceIndex = context.indexOf(oldPreviousSibling);

			}

			node.innerHTML = '';

			summary.getOldParentNode(node).insertBefore(node, oldPreviousSibling ? oldPreviousSibling.nextSibling : summary.getOldParentNode(node).firstChild);

		});

		if(currentCommand === "delete" || currentCommand === 'forwarddelete') {

			// copy-paste of column-reassembling code below, but specific to reparented items
			// pressing delete/forward delete should not reparent elements, it should delete them
			// this will happen if the caret is inside an empty column and I press fwd delete: contents from the next column over are transported to the caret in the first column
			summary.reparented.forEach(function(node, i, context){

				oldPreviousSibling = summary.getOldPreviousSibling(node),
				referenceIndex = context.indexOf(oldPreviousSibling);

				while (referenceIndex > i) {

					node = context[referenceIndex];
					oldPreviousSibling = summary.getOldPreviousSibling(node);

					// swap the nodes around
					context[referenceIndex] = context[i];
					context[i] = node;

					// check the index of the new reference node
					referenceIndex = context.indexOf(oldPreviousSibling);

				}

				// if a reparenting happened to a deleted element
				oldParent = summary.getOldParentNode(node);

				// only undo the reparent if the node hopped between column units

				if( oldParent && node.parentNode && node.parentNode.nodeName==='COLUMN-UNIT' && oldParent !== node.parentNode){

					if( summary.removed.includes(oldParent) ){
						const oldParentOldSibling = summary.getOldPreviousSibling(oldParent)
						const oldParentParent = summary.getOldParentNode(oldParent);
						oldParentParent.insertBefore(oldParent, oldParentOldSibling ? oldParentOldSibling.nextSibling : oldParentParent.firstChild);
					}

					summary.getOldParentNode(node).insertBefore(node, oldPreviousSibling ? oldPreviousSibling.nextSibling : summary.getOldParentNode(node).firstChild);
				}
				
			});			
		}



		if(editor !== undefined){
			_.each(editor.getElement().querySelectorAll('column-set'), function(grid){

				// remove nested....
				_.each(grid.querySelectorAll('column-set, [data-discarded-column]'), function(row){
					elementsToRemove.push(row);
				});

				for(var i = grid.childNodes.length - 1; i >= 0; i--) {

					if(grid.childNodes[i].nodeType === Node.ELEMENT_NODE ) {

						if( grid.childNodes[i].nodeName !== 'COLUMN-UNIT') {
							//console.log('deleting', grid.childNodes[i], 'from', grid);
							grid.childNodes[i].parentNode.removeChild(grid.childNodes[i]);
						} else {
							_.each(grid.childNodes[i].querySelectorAll('column-unit'), function(col){
								elementsToRemove.push(col);
							});
						}

					} else {

						// maintain whitespace formatting
						if(
							grid.childNodes[i].nodeType === Node.TEXT_NODE && 
								(_core.helpers.isWhitespaceNode(grid.childNodes[i]) == false ||
								 grid.childNodes[i].data.match(/\xa0/g) !== null)
						) {
							grid.childNodes[i].parentNode.removeChild(grid.childNodes[i]);
						}

					}

				}

			});
		}

		_.each(elementsToRemove, function(row){
			if(row.parentNode !== null) {
				//console.log('forcing removal of', row);
				row.parentNode.removeChild(row);
			}
		});




		if(currentCommand === "delete" && range !== undefined) {

			var parent = rangeOnMutationStart.commonAncestorContainer,
				row, col;

			while(parent !== null) {

				if(parent.nodeType === Node.ELEMENT_NODE && parent.tagName == 'COLUMN-UNIT') {
					col = parent;
				}

				if(parent.nodeType === Node.ELEMENT_NODE && parent.tagName == 'COLUMN-SET') {
					row = parent;
					break;
				}

				parent = parent.parentNode;
			}

			if(row !== undefined && _core.helpers.isEmptyNode(row)) {

				if(row.firstElementChild === col) {
					//console.log('delete in first col');

					if(row.parentNode !== null) {
						row.parentNode.removeChild(row);
					}

				}

			}

		}

	}


	this.correctColumns = correctColumns;


	var onMutationBeforeSuccess = (editor) =>{

		if(editor === undefined) {
			return;
		}

		var summaries = editor.generateUnreportedMutationSummaries();

		_.each(summaries, function(summary){
			correctColumns(summary, editor);
		});

	}

	var onMutationStart = function(){

		var range = _core.getActiveRange();

		if(range !== undefined) {

			var nodes = _core.helpers.getEffectivelyContainedNodes(range, function(node){

				if(node.nodeType === Node.TEXT_NODE && _core.helpers.isWhitespaceNode(node)) {
					return false;
				}

				return true;
			});

			if(nodes.length === 1 && nodes[0].nodeName === "DIV" && nodes[0].tagName == 'COLUMN-UNIT') {
				range.selectNodeContents(nodes[0]);
				_core.helpers.setActiveRange(range);
			}

		}

		rangeOnMutationStart = _core.getActiveRange();

	}

	var onMutationEnd = function(){
		currentCommand = undefined;
	}

	var onExecCommand = function(editor, command){

		// special intervention to delete empty column set whent the cursor is in the leftmost column
		var range = _core.getActiveRange();
		if(
			command === 'delete' && range && range.collapsed
		){
			const parentElement = range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE ? range.commonAncestorContainer : range.commonAncestorContainer.parentElement;
			const closestColumn = parentElement && parentElement.closest('column-unit');
			if(closestColumn && closestColumn.closest('column-set')){

				const closestColumnSet = closestColumn.closest('column-set');
				const columnSetIsEmpty = Array.from(closestColumnSet.children).filter(node=>node.nodeName == 'COLUMN-UNIT').filter( node=> !_core.helpers.isEmptyNode(node) ).length == 0
				const rangeInFirstColumn = closestColumn == closestColumnSet.firstChild;
				if(
					command === 'delete' &&
					columnSetIsEmpty && rangeInFirstColumn
				){
				
					_core.mutationManager.execute(()=>{
						_core.plugins.shadowDom.preventSlottedElementCorrectionOnce = true;						
						closestColumnSet.remove();
					});
					_core.plugins.shadowDom.preventSlottedElementCorrectionOnce = true;

				}
				
			}
		
		}

		currentCommand = command;
	}

	var onEditorCut = function(editor, event){

		setTimeout(function(){

			/*
				After cutting text out of a column, the browser can set the current range to be in between
				to columns. When inserting new content the content will be stuck in between the two columns causing
				it to be deleted. This will correct the range.
			*/
			var range = _core.getActiveRange();

			if(range 
				&&
				range.collapsed 
				&& range.startContainer === range.endContainer 
				&& range.startOffset === range.endOffset
				&& range.startContainer.nodeType === Node.ELEMENT_NODE
				&& range.startContainer.tagName == 'COLUMN-SET'
			) {

				if(event && event.srcElement && event.srcElement.tagName == 'COLUMN-UNIT') {
					range.selectNodeContents(event.srcElement);
					range.collapse(true);
					_core.helpers.setActiveRange(range);
				}

			}

		});
		
	}

	var checkForEmptyColumns = _.debounce(function(editor){
		
		if(editor == undefined) {
			return;
		}

	}, 400, true)	

	this.events.on('mutation-before-success', onMutationBeforeSuccess);
	this.events.on('mutation-start', onMutationStart);
	this.events.on('mutation-end', onMutationEnd);
	this.events.on('exec-command', onExecCommand);
	this.events.on('editor-cut', onEditorCut);
	this.events.on('editor-content-change', checkForEmptyColumns);
	this.events.on('editor-instanciated', checkForEmptyColumns);

	this.destroy = function(){
		this.events.off('mutation-before-success', onMutationBeforeSuccess);
		this.events.off('mutation-start', onMutationStart);
		this.events.off('mutation-end', onMutationEnd);
		this.events.off('exec-command', onExecCommand);
		this.events.off('editor-cut', onEditorCut);
		this.events.off('editor-content-change', checkForEmptyColumns);
		this.events.off('editor-instanciated', checkForEmptyColumns);
	}


}

export default columnsPlugin

